import type { NextPage } from 'next';
import { Modal, Box, Fade, Button, Typography } from '@mui/material';
import { useStyles } from '../../styles/sharedStyles';
import CloseButton from '../../components/closeButton/closeButton';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  handleAccept: () => void;
}

const CancelExportModal: NextPage<Props> = ({ open, setOpen, handleAccept }) => {
  const classes = useStyles();

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => handleCloseModal()}
    >
      <Fade in={open}>
        <Box className={classes.modal} sx={{ maxWidth: '500px' }}>
          <Box className='u-relative u-py-16 u-pr-16 u-pl-24'>
            <CloseButton handleCloseModal={() => handleCloseModal()} />
            <Box className='u-pt-12 u-pb-20'>
              <Typography className='fs-title-regular'>
                Are you sure you want to cancel your data exports?
              </Typography>
            </Box>
            <Box className='u-text-align-right'>
              <Button
                className='details-button'
                onClick={() => handleCloseModal()}
              >
                Do not cancel
              </Button>
              <Button
                className='contained-button u-ml-12 u-text-transform-none'
                variant='contained'
                sx={{ minWidth: 100 }}
                onClick={() => {
                  handleCloseModal();
                  handleAccept();
                }}
              >
                Yes, cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CancelExportModal;
