import type { NextPage } from 'next';
import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, List, ListItem, ListItemText, ListItemIcon, Tooltip, IconButton, Menu, MenuItem, Divider } from '@mui/material';
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import AutoAwesomeMotionRoundedIcon from '@mui/icons-material/AutoAwesomeMotionRounded';
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import HelpCenterRoundedIcon from '@mui/icons-material/HelpCenterRounded';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import ArrowDropUpRoundedIcon from '@mui/icons-material/ArrowDropUpRounded';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import logo from '../../public/images/logo-white.png';
import smallLogo from '../../public/images/logo-base.png';
import classes from './sideMenu.module.scss';
import { RouteLink } from '../../types/Common.interfaces';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { useBasicFiltersState } from '../../context/basicFiltersState';
import { useNewSegmentState } from '../../context/newSegmentState';
import { Organization } from '../../types/Auth0.interfaces';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';

const SideMenu: NextPage = () => {
  const { user, logout } = useAuth0();
  const { isAdmin, organizations, organization, changeOrganization, userMetadata, error, openHelpPortal } = useGlobalState();
  const {
    menuWidth, setMenuWidth, duration, openMenuWidth, setShowMenuItems, showMenuItems, showLogo, setShowLogo,
    closedMenuWidth, isSideMenuOpen
  } = useUiState();
  const { setAudienceFilters } = useBasicFiltersState();
  const { setNewSegmentFilters } = useNewSegmentState();
  const [anchorEl, setAnchorEl] = useState<any>(null);
  const [orgAnchorEl, setOrgAnchorEl] = useState<any>(null);
  const router = useRouter();

  const collapseMenu = () => {
    setMenuWidth(isSideMenuOpen ? closedMenuWidth : openMenuWidth);
    if (showMenuItems) {
      setShowLogo(false);
      setTimeout(() => {
        setShowMenuItems(!showMenuItems);
      }, duration);
    } else {
      setShowMenuItems(!showMenuItems);
      setTimeout(() => {
        setShowLogo(true);
      }, duration);
    }
  };

  const handleOpenMenu = (event: React.MouseEvent, setOpen: (value: any) => void) => {
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = (setOpen: (value: any) => void) => {
    setOpen(null);
  };

  const logoutWithRedirect = () => {
    logout({ returnTo: typeof window !== 'undefined' ? window.location.origin : 'http://localhost:3000' });
  };

  const sidebarStyle = {
    transition: `width ${duration}ms`
  };

  const sidebarTransitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { width: menuWidth },
    entered: { width: menuWidth },
    exiting: { width: menuWidth },
    exited: { width: menuWidth }
  };

  const links: RouteLink[] = [
    {
      path: '/',
      icon: <HomeRoundedIcon />,
      text: 'Home',
    },
    {
      path: '/database',
      icon: <ViewListRoundedIcon />,
      text: 'Database',
      queryParams: {
        page: '1',
        'page-size': '50',
        tab: 'organizations'
      },
    },
    {
      path: '/segments',
      icon: <AutoAwesomeMotionRoundedIcon />,
      text: 'Segments',
      queryParams: {
        page: '1',
        'page-size': '10',
      },
    },
    {
      path: '/reports',
      icon: <AnalyticsOutlinedIcon />,
      text: 'Reports',
      queryParams: {
        page: '1',
        'page-size': '10',
      },
    },
  ];

  const adminLinks: RouteLink[] = [
    {
      path: '/users',
      icon: <PeopleAltRoundedIcon />,
      text: 'Manage Users',
    },
    {
      path: '/invitations',
      icon: <PersonAddAltRoundedIcon />,
      text: 'Add users',
    },
  ];

  return (
    <Transition timeout={duration}>
      {(state: TransitionStatus) => (
        <Box
          className={classes.sideMenuWrapper}
          sx={{ width: `${menuWidth}px`, ...sidebarStyle, ...sidebarTransitionStyles[state] }}
        >
          <Box>
            <Box className={classes.imageWrapper}>
              <Image
                src={isSideMenuOpen && showLogo ? logo : smallLogo}
                alt='Revenue Base Logo'
                width={isSideMenuOpen && showLogo ? 36 * 5.61 : 36 / 1.47}
                height={36}
                onClick={() => router.push('/')}
              />
            </Box>
            <Box className={classes.clientWrapper}>
              {organizations.length > 1 ? (
                <List sx={{ paddingBottom: 0 }}>
                  <ListItem
                    onClick={(event: React.MouseEvent) => organizations.length > 1 && handleOpenMenu(event, setOrgAnchorEl)}
                    className={classes.sideMenuLink}
                    sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
                  >
                    {isSideMenuOpen || showMenuItems ? (
                      <>
                        <ListItemIcon className='icon-wrapper icon-position' sx={{ color: '#fff' }}>
                          <ApartmentRoundedIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={organization ? organization.display_name : ''}
                          classes={{
                            primary: 'side-menu-name'
                          }}
                        />
                        {organizations.length > 1 && isSideMenuOpen ? orgAnchorEl ? <ArrowDropUpRoundedIcon /> : <ArrowDropDownRoundedIcon /> : null}
                      </>
                    ) : (
                      <Tooltip
                        placement='right'
                        title={`Client: ${organization && organization.display_name}`}
                        classes={{ tooltip: 'custom-tooltip' }}
                      >
                        <ListItemIcon className='icon-wrapper' sx={{ color: '#fff' }}>
                          <ApartmentRoundedIcon />
                        </ListItemIcon>
                      </Tooltip>
                    )}
                  </ListItem>
                  {organizations.length > 1 ? (
                    <Menu
                      elevation={0}
                      anchorEl={orgAnchorEl}
                      keepMounted
                      open={!!orgAnchorEl}
                      onClose={() => handleCloseMenu(setOrgAnchorEl)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PopoverClasses={{
                        paper: classes.popover,
                      }}
                      autoFocus={false}
                    >
                      {organizations.map((org: Organization) => (
                        <MenuItem
                          key={org.id}
                          onClick={() => {
                            handleCloseMenu(setOrgAnchorEl);
                            changeOrganization(org);
                            setAudienceFilters([]);
                            setNewSegmentFilters([]);
                          }}
                          className={organization && org.id === organization.id ?
                            `${classes.submenuItem} ${classes.submenuItemActive}` :
                            `${classes.submenuItem}`
                          }
                        >
                          {org.display_name}
                        </MenuItem>
                      ))}
                    </Menu>
                  ) : null}
                </List>
              ) : null}
              {organizations.length > 1 && <Divider sx={{ borderColor: 'rgba(196, 196, 196, 0.5)' }} />}
            </Box>
            <Box>
              <List>
                {links.map((link: RouteLink) => (
                  <ListItem
                    key={link.path}
                    className={router.pathname.split('/')[1] === link.path.split('/')[1] ?
                      `${classes.sideMenuLinkActive} ${classes.sideMenuLink}` : classes.sideMenuLink
                    }
                    onClick={() => {
                      error ?
                        window.location.reload() :
                        router.push(!link.queryParams ? link.path : { pathname: link.path, query: new URLSearchParams(link.queryParams).toString()});
                    }}
                    disableGutters
                    sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
                  >
                    {isSideMenuOpen || showMenuItems ? (
                      <>
                        <ListItemIcon
                          className='icon-wrapper icon-position'
                          sx={router.pathname.split('/')[1] === link.path.split('/')[1] ? { color: '#00B5B0' } : { color: '#fff' }}
                        >
                          {link.icon}
                        </ListItemIcon>
                        <ListItemText primary={link.text} sx={{ paddingLeft: '8px' }} />
                      </>
                    ) : (
                      <Tooltip placement='right' title={link.text} classes={{ tooltip: 'custom-tooltip' }}>
                        <ListItemIcon
                          className='icon-wrapper'
                          sx={router.pathname.split('/')[1] === link.path.split('/')[1] ? { color: '#00B5B0' } : { color: '#fff' }}
                        >
                          {link.icon}
                        </ListItemIcon>
                      </Tooltip>
                    )}
                  </ListItem>
                ))}
              </List>
              <Tooltip placement='right' title={isSideMenuOpen ? 'Collapse menu' : 'Expand menu'} classes={{ tooltip: 'custom-tooltip' }}>
                <IconButton onClick={() => collapseMenu()} className={classes.collapseButton}>
                  {isSideMenuOpen ? <KeyboardArrowLeftIcon /> : <ChevronRightIcon />}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          <Box>
            <List>
              <Link href='/export-history?page=1&page-size=10' passHref>
                <ListItem
                  className={router.pathname === '/export-history' ? `${classes.sideMenuLinkActive} ${classes.sideMenuLink}` : classes.sideMenuLink}
                  disableGutters
                  sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
                >
                  {isSideMenuOpen || showMenuItems ? (
                    <>
                      <ListItemIcon
                        className='icon-wrapper icon-position'
                        sx={router.pathname === '/export-history' ? { color: '#00B5B0' } : { color: '#fff' }}
                      >
                        <HistoryOutlinedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Export History' sx={{ whiteSpace: 'nowrap', paddingLeft: '8px' }} />
                    </>
                  ) : (
                    <Tooltip placement='right' title='Export History' classes={{ tooltip: 'custom-tooltip' }}>
                      <ListItemIcon className='icon-wrapper' sx={{ color: '#fff' }}>
                        <HistoryOutlinedIcon />
                      </ListItemIcon>
                    </Tooltip>
                  )}
                </ListItem>
              </Link>
              <ListItem
                className={classes.sideMenuLink}
                disableGutters
                onClick={() => openHelpPortal()}
                sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
              >
                {isSideMenuOpen || showMenuItems ? (
                  <>
                    <ListItemIcon className='icon-wrapper icon-position' sx={{ color: '#fff' }}>
                      <HelpCenterRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Help' sx={{ whiteSpace: 'nowrap', paddingLeft: '8px' }} />
                  </>
                ) : (
                  <Tooltip placement='right' title='Help' classes={{ tooltip: 'custom-tooltip' }}>
                    <ListItemIcon className='icon-wrapper' sx={{ color: '#fff' }}>
                      <HelpCenterRoundedIcon />
                    </ListItemIcon>
                  </Tooltip>
                )}
              </ListItem>
              {isAdmin && (
                <ListItem
                  className={anchorEl ? `${classes.sideMenuLinkActive} ${classes.sideMenuLink}` : classes.sideMenuLink}
                  disableGutters
                  sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
                  onClick={(event: React.MouseEvent) => !anchorEl ? handleOpenMenu(event, setAnchorEl) : handleCloseMenu(setAnchorEl)}
                >
                  {isSideMenuOpen || showMenuItems ? (
                    <>
                      <ListItemIcon className='icon-wrapper icon-position' sx={anchorEl ? { color: '#00B5B0' } : { color: '#fff' }}>
                        <ManageAccountsRoundedIcon />
                      </ListItemIcon>
                      <ListItemText primary='Admin Settings' sx={{ whiteSpace: 'nowrap', paddingLeft: '8px' }} />
                      {isSideMenuOpen && <ArrowRightRoundedIcon />}
                    </>
                  ) : (
                    <Tooltip placement='right' title='Admin Settings' classes={{ tooltip: 'custom-tooltip' }}>
                      <ListItemIcon className='icon-wrapper' sx={anchorEl ? { color: '#00B5B0' } : { color: '#fff' }}>
                        <ManageAccountsRoundedIcon />
                      </ListItemIcon>
                    </Tooltip>
                  )}
                  <Menu
                    elevation={0}
                    anchorEl={anchorEl}
                    keepMounted
                    open={!!anchorEl}
                    onClose={() => handleCloseMenu(setAnchorEl)}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PopoverClasses={{
                      paper: classes.popover
                    }}
                    autoFocus={false}
                  >
                    {adminLinks.map((link: RouteLink, index: number) => (
                      <MenuItem
                        key={index}
                        onClick={() => {
                          handleCloseMenu(setAnchorEl);
                          router.push(
                            !link.queryParams ? link.path : { pathname: link.path, query: new URLSearchParams(link.queryParams).toString()  }
                          );
                        }}
                        className={router.pathname === link.path ? `${classes.submenuItemActive} ${classes.submenuItem}` : classes.submenuItem}
                      >
                        <ListItemIcon
                          className='icon-wrapper icon-position'
                          sx={router.pathname === link.path ? { color: '#00B5B0' } : { color: '#fff' }}
                        >
                          {link.icon}
                        </ListItemIcon>
                        <ListItemText primary={link.text} />
                      </MenuItem>
                    ))}
                  </Menu>
                </ListItem>
              )}
              <ListItem
                className={classes.sideMenuLink}
                disableGutters
                sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
              >
                {isSideMenuOpen || showMenuItems ? (
                  <>
                    <ListItemIcon
                      className='icon-wrapper icon-position'
                      sx={{ color: '#fff' }}
                    >
                      <PersonIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={user && user.email ? user.email : 'Profile'}
                      sx={{ whiteSpace: 'nowrap', overflowX: 'hidden', paddingLeft: '8px' }}
                    />
                  </>
                ) : (
                  <Tooltip
                    classes={{ tooltip: 'custom-tooltip' }}
                    placement='right'
                    title={user ? user[userMetadata].first_name && user[userMetadata].last_name ?
                      `${user[userMetadata].first_name} ${user[userMetadata].last_name}` : user.email ? user.email : 'Profile' : 'Profile'}
                  >
                    <ListItemIcon className='icon-wrapper' sx={router.pathname === '/profile' ? { color: '#00B5B0' } : { color: '#fff' }}>
                      <PersonIcon />
                    </ListItemIcon>
                  </Tooltip>
                )}
              </ListItem>
              <ListItem
                className={classes.sideMenuLink}
                disableGutters
                onClick={() => logoutWithRedirect()}
                sx={!(isSideMenuOpen) ? { display: 'flex', justifyContent: 'center' } : {}}
              >
                {isSideMenuOpen || showMenuItems ? (
                  <>
                    <ListItemIcon className='icon-wrapper icon-position' sx={{ color: '#fff' }}>
                      <ExitToAppRoundedIcon />
                    </ListItemIcon>
                    <ListItemText primary='Logout' sx={{ whiteSpace: 'nowrap', paddingLeft: '8px' }} />
                  </>
                ) : (
                  <Tooltip placement='right' title='Logout' classes={{ tooltip: 'custom-tooltip' }}>
                    <ListItemIcon className='icon-wrapper' sx={{ color: '#fff' }}>
                      <ExitToAppRoundedIcon />
                    </ListItemIcon>
                  </Tooltip>
                )}
              </ListItem>
            </List>
          </Box>
        </Box>
      )}
    </Transition>
  );
};

export default SideMenu;