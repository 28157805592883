import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles(() => ({
  modal: {
    position: 'absolute',
    background: '#fff',
    borderRadius: '10px',
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  closeBtn: {
    position: 'absolute',
    right: '10px',
    top: '0px',
    width: '30px',
    height: '30px',
  },
  exportModal: {
    position: 'absolute',
    background: '#fff',
    borderRadius: '10px',
    maxWidth: 700,
    width: '100%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxHeight: 'calc(100vh - 50px)',
    overflowY: 'scroll',
  },
}));
