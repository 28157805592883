import type { NextPage } from 'next';
import { useState } from 'react';
import { Box, FormControl, Select, InputLabel, MenuItem, SelectChangeEvent, Modal, Fade } from '@mui/material';
import classes from './selectOrganization.module.scss';
import { useGlobalState } from '../../context/globalState';
import { Organization } from '../../types/Auth0.interfaces';

const SelectModal: NextPage = () => {
  const { organizations, setOrganization, setTrialAccount } = useGlobalState();
  const [value, setValue] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(true);

  const handleSelectOrganization = (event: SelectChangeEvent<string>) => {
    const org = organizations.find((client: Organization) => client.id === event.target.value);
    if (org) {
      setValue(org.id);
      setOrganization(org);
      setTrialAccount(!!org.metadata.trial_account);
      localStorage.organizationId = org.id;
      setOpenModal(false);
    }
  };

  return (
    <Modal
      open={openModal}
    >
      <Fade in={openModal}>
        <Box className={classes.modal} sx={{ maxWidth: '600px' }}>
          {organizations.length > 0 ? (
            <Box>
              <Box>
                <Box component='p' className={classes.paragraph}>Select organization to enter:</Box>
              </Box>
              <FormControl variant='standard' className='full-width' sx={{ minWidth: '200px', paddingBottom: '20px' }}>
                <InputLabel id='organization-label'>Organization</InputLabel>
                <Select
                  labelId='organization-label'
                  id='organization'
                  value={value}
                  onChange={(event: SelectChangeEvent<string>) => handleSelectOrganization(event)}
                  sx={{ textAlign: 'left' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center'
                    },
                    classes: {
                      paper: 'paper'
                    }
                  }}
                >
                  {organizations.map((org: Organization) => (
                    <MenuItem key={org.id} value={org.id}>
                      {org.display_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          ) : (
            <Box>
              <Box>
                <Box
                  component='p'
                  className={classes.paragraph}
                >
                  You don&apos;t have access to any organization. Please contact your organization admin to add you!
                </Box>
              </Box>
            </Box>
          )}
        </Box>
      </Fade>
    </Modal>
  );
};

export default SelectModal;
