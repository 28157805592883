import type { NextPage } from 'next';
import Head from 'next/head';
import { useState } from 'react';
import Loading from '../loading/loading';
import SelectOrganization from '../selectOrganization/selectOrganization';
import SideMenu from '../sideMenu/sideMenu';
import Toast from '../toast/toast';
import CancelExportModal from '../cancelExportModal/cancelExportModal';
import FileExport from '../fileExport/fileExport';
import TamDrawer from '../tamDrawer/tamDrawer';
import PersonaDefinitionDrawer from '../personaDefinitionDrawer/personaDefinitionDrawer';
import ExportHistoryDrawer from '../exportHistoryDrawer/exportHistoryDrawer';
import PeopleDrawer from '../peopleDrawer/peopleDrawer';
import { useGlobalState } from '../../context/globalState';
import { useUiState } from '../../context/uiState';
import { useExportState } from '../../context/exportState';
import useWindowSize from '../../utils/useWindowSize';
import { useAuth0 } from '@auth0/auth0-react';
import { Transition } from 'react-transition-group';
import { TransitionStatus } from 'react-transition-group/Transition';
import CustomInsightsDrawer from '../customInsightsDrawer/customInsightsDrawer';

interface Props {
  title: string;
  padding?: boolean;
}

const Layout: NextPage<Props> = ({ title, children, padding }) => {
  const { isLoading, isAuthenticated } = useAuth0();
  const { loading, organization, checkingAdminRole, loadingCubejs } = useGlobalState();
  const {
    duration, menuWidth, isExportOpen, setIsExportOpen, openTamDrawer, setOpenTamDrawer, openPersonaDefinitionDrawer, setOpenPersonaDefinitionDrawer,
    openExportHistoryDrawer, setOpenExportHistoryDrawer, openPeopleDrawer, setOpenPeopleDrawer, openCustomInsightsDrawer, setOpenCustomInsightsDrawer
  } = useUiState();
  const { cancelExport } = useExportState();
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const { width } = useWindowSize();

  const contentStyle = {
    transition: `width ${duration}ms`,
  };

  const contentTransitionStyles: { [id: string]: React.CSSProperties } = {
    entering: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
    entered: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
    exiting: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
    exited: { width: typeof window !== 'undefined' ? window.innerWidth - menuWidth : width - menuWidth },
  };

  return (
    <div className='app'>
      <Head>
        <title>RevenueBase | {title}</title>
        <meta name='description' content='RevenueBase Portal' />
        <link rel='icon' href='/favicon.ico' />
      </Head>
      <main style={!isAuthenticated ? { background: '#2e2e2e', minHeight: '100vh' } : {}}>
        {!isAuthenticated ? <></> : (
          <>
            <SideMenu />
            <Transition timeout={duration}>
              {(state: TransitionStatus) => (
                <div className='main-container' style={{ ...contentStyle, ...contentTransitionStyles[state] }}>
                  {children}
                  {!loading && !organization ? <SelectOrganization /> : null}
                  {isExportOpen ? <FileExport setOpenCancelModal={setOpenCancelModal} layoutPadding={!!padding} /> : null}
                </div>
              )}
            </Transition>
          </>
        )}
        <CancelExportModal
          open={openCancelModal}
          setOpen={setOpenCancelModal}
          handleAccept={() => {
            setIsExportOpen(false);
            cancelExport();
          }}
        />
        <TamDrawer
          open={openTamDrawer}
          setOpen={setOpenTamDrawer}
        />
        <PersonaDefinitionDrawer
          open={openPersonaDefinitionDrawer}
          setOpen={setOpenPersonaDefinitionDrawer}
        />
        <ExportHistoryDrawer
          open={openExportHistoryDrawer}
          setOpen={setOpenExportHistoryDrawer}
        />
        <PeopleDrawer
          open={openPeopleDrawer}
          setOpen={setOpenPeopleDrawer}
        />
        <CustomInsightsDrawer
          open={openCustomInsightsDrawer}
          setOpen={setOpenCustomInsightsDrawer}
        />
        <Toast />
      </main>
      {isLoading || loading || (checkingAdminRole && organization) || loadingCubejs ? (
        <Loading transparentBackground={false} />
      ) : null}
    </div>
  );
};

export default Layout;
