import type { NextPage } from 'next';
import { Box, Tooltip, IconButton } from '@mui/material';
import CloseIconRounded from '@mui/icons-material/CloseRounded';
import { useStyles } from '../../styles/sharedStyles';

interface Props {
  handleCloseModal: () => void;
}

const CloseButton: NextPage<Props> = ({ handleCloseModal }) => {
  const classes = useStyles();

  return (
    <Box component='span' className={classes.closeBtn} onClick={() => handleCloseModal()}>
      <Tooltip title={'Close'} placement='bottom' classes={{ tooltip: 'custom-tooltip' }}>
        <IconButton>
          <CloseIconRounded fontSize='small' sx={{ color: '#2f3b4a'}} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};

export default CloseButton;
